import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Content from "../components/content"
import SEO from "../components/seo"
import ArticleList from "../components/article-list"

const Page = ({ data }) => (
  <>
    <SEO title="Welcome" />

    <Img fluid={data.strapiPage.image.childImageSharp.fluid} />
    <Content content={data.strapiPage.content} />
    <ArticleList />
    <Link to="/articles/">Go to Articles</Link>
  </>
)

export default Page

export const query = graphql`
  query {
    strapiPage(slug: { eq: "home" }) {
      id
      title
      content
      image {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

import styles from './article-list.module.scss'

export default () => (
   <StaticQuery

      query = { graphql`  
         query IndexQuery {
         allStrapiArticle {
            edges {
               node {
               id
               image {
                  childImageSharp {
                     fixed(width: 200, height: 125) {
                     ...GatsbyImageSharpFixed
                     }
                  }
               }
               title
               slug
               summary
               }
            }
         }
         }
      `

      }

      render = { data => (
         <div className={styles.container}>
            {data.allStrapiArticle.edges.map(document => (
               <article key={document.node.id}>
               <h2>
                 <Link to={`/articles/${document.node.slug}`}>{document.node.title}</Link>
               </h2>
               <Link to={`/articles/${document.node.slug}`}><Img fixed={document.node.image.childImageSharp.fixed}/></Link>
               <ReactMarkdown  
                 source={document.node.summary}
                 transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}
                 className='indexArticle'
                 escapeHtml={false}
               />
               <Link to={`/articles/${document.node.slug}`}>Read more</Link>
             </article>
            ))}
         </div>
      )}

   />
)